<template>
  <div class="view-home">
    <div class="home-img-bg">
      <div class="dx-person-bg"></div>
      <div class="dx-fire-bg"></div>
    </div>
    <div class="view-home-main">
      <div v-if="user.id" class="view-home-header">
        <div class="header-left flex-c">
          <div class="in-header-left">
            <img class="wh100" :src="user.avatar" alt="" srcset="" />
          </div>
        </div>
        <div class="header-right">
          <div class="right-row1 flex-s">
            <div class="row1-col1">
              <CompCoin imgName="coin-z" :num="user.integral" />
            </div>
            <div @click="$router.push('/BagRecharge')" class="row1-col2">
              <CompCoin :num="user.bean" />
              <div class="float-add"></div>
            </div>
          </div>
          <div v-if="user.name" class="right-row2">
            <div class="in-right-row2">昵称：{{ user.name }}</div>
            <div class="VIPbox" v-for="item in vipimgdata" :key="item.id">
              <img v-if="item.id == user.vip_level" :src="item.img" alt="" />
            </div>
          </div>
          <div class="right-row3">
            <div class="in-right-row3">ID：{{ user.id }}</div>
          </div>

          <div class="cdkredbox" @click="showcdkbox">
            <img src="../../assets/newImages/cdkboxicon.png" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="view-home-header-not-login flex-b">
        <div @click="loginOnClick" class="not-login-left flex-c">登录/注册</div>
        <div class="not-login-center">
          <div class="row1">
            <CompCoin imgName="coin-z" num="0.00" />
          </div>
        </div>
        <div class="not-login-right">
          <div class="row2">
            <CompCoin num="0.00" />
            <!-- <div class="float-add"></div> -->
          </div>
        </div>
      </div>
      <div v-if="globalInfo?.site_bulletin" class="view-home-notice-box flex-c">
        <div class="notice-box flex-s">
          <div>公告：</div>
          <van-notice-bar
            style="height: 0.12rem; flex: 1; font-size: 0.1rem; padding: 0"
            color="#fff"
            speed="30"
            background="rgba(255, 255, 255, 0)"
            :text="globalInfo?.site_bulletin" />
        </div>
      </div>
      <div class="view-home-content">
        <div
          class="home-content-row1"
          @click="goToPageOnClick('/BlindBox')"></div>
        <div class="home-content-row2">
          <div
            class="home-row2-col2"
            @click="goToPageOnClick('/RankList')"></div>
          <div class="home-row2-col1" @click="goToPageOnClick('/Roll')"></div>
        </div>
        <div
          class="home-content-row3"
          @click="goToPageOnClick('/dustBatter')"></div>
        <div class="home-content-row4" @click="goToPageOnClick('/Lucky')"></div>
        <div
          class="home-content-row5"
          @click="goToPageOnClick('/welfare')"></div>
      </div>
    </div>
    <van-overlay :lock-scroll="true" :show="SignInShow">
      <LoginPopup v-if="SignInShow" @close="closeOnClick" />
    </van-overlay>
    <van-overlay :lock-scroll="true" :show="cdkboxshow">
      <CompPopup @close="showcdkbox">
        <div class="frombox">
          <div class="fromtitle">CDK补给站</div>
          <div class="frominput">
            <div class="cdkimgbox">
              <img src="../../assets/newImages/cdkboximg.png" alt="" />
            </div>
            <div class="inputbox">
              <input
                class="put putc"
                type="text"
                placeholder="请输入密令"
                v-model="cdkvalue" />
            </div>
            <div class="btnbox">
              <div class="btn" @click="getred">确认</div>
            </div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
    <DustRank />
    <Tabbar />
  </div>
</template>

<script>
import { RedOpen } from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
import LoginPopup from "@/components/common/LoginPopup";
import DustRank from "../DustRank/index.vue";
import PersonDxJson from "@/assets/gif/person-dx.json"; // 导入 JSON 文件
import FireDxJson from "@/assets/gif/fire-dx.json"; // 导入 JSON 文件
import lottie from "lottie-web";
export default {
  components: {
    LoginPopup,
    DustRank,
  },
  name: "Home",
  computed: {
    ...mapState([
      "user",
      "globalInfo",
      "IndividualsShow",
      "RegisterShow",
      "SignInShow",
    ]),
  },
  data() {
    return {
      // loginPopUpShow: false,
      vipimgdata: [
        {
          id: 0,
          img: require("../../assets/newImages/vip-v0.png"),
        },
        {
          id: 1,
          img: require("../../assets/newImages/vip-v1.png"),
        },
        {
          id: 2,
          img: require("../../assets/newImages/vip-v2.png"),
        },
        {
          id: 3,
          img: require("../../assets/newImages/vip-v3.png"),
        },
        {
          id: 4,
          img: require("../../assets/newImages/vip-v4.png"),
        },
        {
          id: 5,
          img: require("../../assets/newImages/vip-v5.png"),
        },
        {
          id: 6,
          img: require("../../assets/newImages/vip-v6.png"),
        },
        {
          id: 7,
          img: require("../../assets/newImages/vip-v7.png"),
        },
        {
          id: 8,
          img: require("../../assets/newImages/vip-v8.png"),
        },
      ],

      cdkboxshow: false,
      cdkvalue: "",
      lottie1: null,
    };
  },
  created() {
    this.setLoading(true);
    setTimeout(() => {
      this.setLoading(false);
    }, 3000);
  },
  mounted() {
    this.lottie1 = lottie.loadAnimation({
      container: document.querySelector(".dx-person-bg"), // 动画容器
      renderer: "svg", // 渲染器类型，可以选择 'svg' 或 'html'
      loop: true, // 是否循环播放
      autoplay: true, // 是否自动播放
      animationData: PersonDxJson, // JSON 文件的路径
    });
    this.lottie2 = lottie.loadAnimation({
      container: document.querySelector(".dx-fire-bg"), // 动画容器
      renderer: "svg", // 渲染器类型，可以选择 'svg' 或 'html'
      loop: true, // 是否循环播放
      autoplay: true, // 是否自动播放
      animationData: FireDxJson, // JSON 文件的路径
    });
  },
  methods: {
    //获取cdk红包
    getred() {
      if (this.cdkvalue == "") {
        this.$message({
          message: "请输入CDK",
          type: "error",
        });
      } else {
        RedOpen(2, _, this.cdkvalue).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: `领取成功,金额为${res.data.data}`,
              type: "success",
            });
            this.cdkvalue = "";
            this.showcdkbox();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.showcdkbox();
          }
        });
      }
    },
    showcdkbox() {
      this.cdkboxshow = !this.cdkboxshow;
    },
    ...mapMutations(["setLoading", "GetSignInShow"]),
    closeOnClick() {
      this.GetSignInShow(false);
    },
    loginOnClick() {
      this.GetSignInShow(true);
    },
    goToPageOnClick(item) {
      if (!this?.user?.id) {
        this.GetSignInShow(true);
        return false;
      }
      this.$router.push(item);
    },
  },
};
</script>

<style lang="scss">
.view-home {
  width: 100%;
  min-height: 100%;
  // background: skyblue;
  padding-top: 0rem;
  // @include bgImg("home-bg-g");
  // background-size: cover;
  .home-img-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include bgImg("home-bg-g-2");
    background-size: 100%;
    .dx-person-bg {
      position: absolute;
      bottom: 0.3rem;
      left: -0.5rem;
      width: 3rem;
    }
    .dx-fire-bg {
      position: absolute;
      bottom: 0rem;
      left: 0rem;
      width: 100%;
    }
  }
  .view-home-main {
    padding-top: 0.1rem;
  }
  .view-home-header {
    padding: 0 0.2rem;
    display: flex;
    align-items: stretch;
    margin-bottom: 0.12rem;
    .header-left {
      width: 0.82rem;
      height: 0.82rem;
      margin-right: 0.08rem;
      @include bgImg("home-head-bg");
      .in-header-left {
        width: 0.79rem;
        height: 0.79rem;
      }
    }
    .header-right {
      display: flex;
      align-items: stretch;
      align-content: space-between;
      flex-wrap: wrap;
      font-size: 0.12rem;
      flex: 1;
      position: relative;
      .right-row1 {
        width: 100%;
        .row1-col1 {
          margin-right: 0.08rem;
          padding: 0.02rem 0.3rem 0.02rem 0.06rem;
          width: fit-content;
          @include bgImg("home-header-coin-bg");
        }
        .row1-col2 {
          width: fit-content;
          padding: 0.02rem 0.3rem 0.02rem 0.06rem;
          position: relative;
          @include bgImg("home-header-coin-bg");
          .float-add {
            position: absolute;
            right: 0;
            top: 0;
            width: 0.19rem;
            height: 100%;
            @include bgImg("home-header-add-bg");
          }
        }
      }
      .right-row2 {
        width: 100%;
        display: flex;
        align-items: center;
        .in-right-row2 {
          padding: 0.02rem 0.13rem 0.02rem 0.1rem;
          width: fit-content;
          margin-top: 0.04rem;
          // @include bgImg("home-header-coin-bg");
        }
        .VIPbox {
          img {
            width: 0.48rem;
          }
        }
      }
      .right-row3 {
        width: 100%;
        .in-right-row3 {
          width: fit-content;
          padding: 0.02rem 0.3rem 0.02rem 0.1rem;
          // @include bgImg("home-header-coin-bg");
        }
      }
      .cdkredbox {
        position: absolute;
        right: 0;
        top: 0.3rem;
        width: 0.57rem;
        height: 0.57rem;
        img {
          width: 100%;
        }
      }
    }
  }
  .view-home-header-not-login {
    padding: 0 0.2rem;
    margin-bottom: 0.21rem;
    .not-login-left {
      font-weight: 500;
      font-size: 0.14rem;
      color: #000000;
      width: 0.96rem;
      height: 0.3rem;
      @include bgImg("login-bg");
    }
    .not-login-center {
      .row1 {
        margin-right: 0.08rem;
        padding: 0.02rem 0.4rem 0.02rem 0.1rem;
        width: fit-content;
        @include bgImg("home-header-coin-bg");
      }
    }
    .not-login-right {
      .row2 {
        padding: 0.02rem 0.4rem 0.02rem 0.1rem;
        width: fit-content;
        position: relative;
        @include bgImg("home-header-coin-bg");
        .float-add {
          position: absolute;
          right: 0;
          top: 0;
          width: 0.19rem;
          height: 100%;
          @include bgImg("home-header-add-bg");
        }
      }
    }
  }
  .view-home-notice-box {
    padding: 0 0.2rem;
    margin-bottom: 0.18rem;
    .notice-box {
      width: 100%;
      padding: 0 0.08rem;
      font-size: 0.1rem;
      height: 0.2rem;
      line-height: 0.2rem;
      @include bgImg("home-notice-bg");
    }
  }

  .view-home-content {
    // position: relative;
    // position: absolute;
    height: 60vh;
    bottom: 32vw;
    // right: 0.2rem;
    padding-right: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    .home-content-row1 {
      width: 1.9rem;
      height: 0.79rem;
      // position: absolute;
      // top: 0rem;
      // right: 0.13rem;
      @include bgImg("home-bg1");
    }
    .home-content-row2 {
      width: 1.9rem;
      height: 0.79rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      // position: absolute;
      // top: 0.79rem;
      // right: 0.15rem;
      .home-row2-col1 {
        width: 0.907rem;
        height: 0.656rem;
        // position: absolute;
        // top: 0.02rem;
        // right: 0.94rem;
        @include bgImg("home-bg2");
      }
      .home-row2-col2 {
        width: 0.879rem;
        height: 0.656rem;
        // position: absolute;
        // top: 0.06rem;
        // right: 0rem;
        @include bgImg("home-bg3");
      }
    }
    .home-content-row3 {
      width: 1.7rem;
      height: 0.78rem;
      // position: absolute;
      // top: 1.77rem;
      // right: 0.15rem;
      @include bgImg("home-bg4");
    }
    .home-content-row4 {
      width: 1.73rem;
      height: 0.92rem;
      // position: absolute;
      // top: 2.58rem;
      // right: 0.08rem;
      @include bgImg("home-bg5");
    }
    .home-content-row5 {
      width: 1.57rem;
      height: 0.835rem;
      // position: absolute;
      // top: 3.48rem;
      // right: 0.15rem;
      @include bgImg("home-bg6");
    }
  }
}
.frombox {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .fromtitle {
    text-align: center;
    font-weight: 500;
    font-size: 0.18rem;
    color: #ffffff;
    margin-bottom: 0.12rem;
  }
  .frominput {
    width: 2.75rem;
    margin: 0 auto;
    .inputbox {
      width: 100%;
      margin-bottom: 0.16rem;
      position: relative;
      .inputtitle {
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
        margin-bottom: 0.08rem;
      }
      .put {
        width: 100%;
        height: 0.38rem;
        background: #413f3e;
        text-decoration: none;
        border: 0;
        font-weight: 400;
        font-size: 0.14rem;
        color: #999999;
        text-indent: 0.12rem;
      }
      .putc {
        text-indent: unset;
        text-align: center;
        line-height: 0.38rem;
      }
    }
    .cdkimgbox {
      width: 1.12rem;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .btnbox {
    width: 100%;
    margin-top: 0.12rem;
    .btn {
      width: 1.4rem;
      height: 0.55rem;
      background: url("../../assets/newImages/user-sbtn.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #312f2d;
      margin: 0 auto;
      font-family: PingFang SC, PingFang SC;
    }
  }
}
</style>
