<template>
  <div class="dustrank">
    <div class="iconbox" @click="gomaill">
      <img src="../../assets/newImages/dust-icon1.png" alt="" />
      <div v-if="unreaddata > 0" class="tipsicon"></div>
    </div>
    <a
      target="_blank"
      href="https://kefu.tmskins.com/index/index/home?theme=7571f9&visiter_id=&visiter_name=&avatar=&business_id=1&groupid=0"
      class="iconbox ic2"
    >
      <img src="../../assets/newImages/dust-icon2.png" alt="" />
    </a>
    <div class="iconbox" @click="showset">
      <img src="../../assets/newImages/dust-icon3.png" alt="" />
    </div>
    <!-- 系统设置 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="settings"
    >
      <CompPopup @close="showset">
        <div class="frombox">
          <div class="fromtitle">系统设置</div>
          <div class="frominput">
            <div class="setbox">
              <p>背景音乐</p>
              <div class="setbut" @click="bgmusic">
                <div class="butbg1" v-show="bgmusictype == true">
                  <!-- <div class="buttext">ON</div> -->
                </div>
                <div class="butbg2" v-show="bgmusictype == false">
                  <!-- <div class="buttext">OFF</div> -->
                </div>
              </div>
            </div>
            <div class="setbox">
              <p>音效</p>
              <div class="setbut" @click="sound">
                <div class="butbg1" v-show="soundtype == true">
                  <!-- <div class="buttext">ON</div> -->
                </div>
                <div class="butbg2" v-show="soundtype == false">
                  <!-- <div class="buttext">OFF</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="btnbox"></div>
        </div>
      </CompPopup>
    </van-overlay>
  </div>
</template>

<script>
import { getunreadApi } from "../../network/api";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      settings: false,
      bgmusictype: false,
      soundtype: false,

      unreaddata: "",
    };
  },
  created() {
    this.getunreaddata();
  },
  computed: {
    ...mapState(["user", "globalMusic", "clickBgMusic"]),
  },
  methods: {
    async getunreaddata() {
      const res = await getunreadApi();
      this.unreaddata = res.data.data.unread_count;
    },
    showset() {
      this.settings = !this.settings;
    },
    gomaill() {
      this.$router.push("/Mail");
    },
    bgmusic() {
      if (this.globalMusic.paused) {
        this.globalMusic.play();
        this.globalMusic.loop = true;
        this.bgmusictype = true;
      } else {
        this.globalMusic.pause();
        this.bgmusictype = false;
      }
    },
    sound() {
      // 切换 soundtype 状态
      this.soundtype = !this.soundtype;
      if (this.soundtype == true) {
        // 确保只添加一次事件监听器
        const playSound = () => {
          // 只在 soundtype 为 true 时播放音效
          if (this.soundtype) {
            this.clickBgMusic.currentTime = 0; // 从头开始播放
            this.clickBgMusic.play();
          }
        };

        // 添加鼠标点击事件监听器
        document.addEventListener("click", playSound);
        this.soundClickHandler = playSound; // 保存引用以便后续移除

        // 更新界面状态
        this.soundtype = true; // 假设你有一个方法来更新按钮状态
      } else {
        // 如果关闭声音时，暂停音效并重置时间
        this.clickBgMusic.pause();
        this.clickBgMusic.currentTime = 0; // 重置时间
        document.removeEventListener("click", this.soundClickHandler);
        // 更新界面状态
        this.soundtype = false; // 假设你有一个方法来更新按钮状态
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dustrank {
  width: 0.4rem;
  height: 1.18rem;
  @include bgImg("dust-rankbg");
  position: fixed;
  left: 0;
  bottom: 0.9rem;
  padding: 0.1rem;
  .iconbox {
    width: 0.22rem;
    height: 0.22rem;
    display: block;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .tipsicon {
      position: absolute;
      top: 0;
      right: -0.02rem;
      width: 0.08rem;
      height: 0.08rem;
      border-radius: 50%;
      background: #ff6e00;
    }
  }
  .ic2 {
    margin: 0.12rem 0;
  }
  .frombox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .fromtitle {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
      margin-bottom: 0.12rem;
    }
    .frominput {
      width: 2.75rem;
      margin: 0 auto;
      .inputbox {
        width: 100%;
        margin-bottom: 0.16rem;
        position: relative;
        .inputtitle {
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-bottom: 0.08rem;
        }
        .put {
          width: 100%;
          height: 0.38rem;
          background: #413f3e;
          text-decoration: none;
          border: 0;
          font-weight: 400;
          font-size: 0.14rem;
          color: #999999;
          text-indent: 0.12rem;
        }
        .putc {
          text-indent: unset;
          text-align: center;
          line-height: 0.38rem;
        }
        .yzmput {
          position: absolute;
          width: 0.96rem;
          height: 0.3rem;
          right: 0.05rem;
          top: 0.255rem;
          background: url("../../assets/newImages/send-code-bg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.12rem;
          color: #312f2d;
        }
      }
      .tipstext {
        font-weight: 400;
        font-size: 0.12rem;
        color: #999999;
      }
      .textc {
        display: flex;
        justify-content: center;
      }
      .textd {
        text-decoration: underline;
      }
      .imgsbox {
        width: 100%;
        display: flex;
        height: 0.74rem;
        background: #040404;
        overflow-x: auto;
        .headimg {
          display: flex;
          justify-content: center;
          align-items: center;
          .l_imgbox {
            width: 0.48rem;
            height: 0.48rem;
            border-radius: 50%;
            margin: 0 0.06rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .acimgbox {
            width: 0.48rem;
            height: 0.48rem;
            border-radius: 50%;
            margin: 0 0.06rem;
            border: 0.02rem solid #ffec45;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.46rem;
              height: 0.46rem;
              border-radius: 50%;
            }
          }
        }
      }
      .setbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.45rem;
        p {
          width: 40%;
          text-align: right;
        }
        .setbut {
          width: 50%;
          .butbg1 {
            width: 1.18rem;
            height: 0.38rem;
            @include bgImg("musicon");
            position: relative;
            // .buttext {
            //   position: absolute;
            //   width: 0.28rem;
            //   height: 0.28rem;
            //   background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
            //   box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
            //     inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
            //     inset 1px 1px 1px 0px #ffffff;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   font-weight: 550;
            //   font-size: 0.12rem;
            //   color: #151413;
            //   top: -0.03rem;
            //   right: 0.05rem;
            // }
          }
          .butbg2 {
            width: 1.18rem;
            height: 0.38rem;
            background: #413f3e;
            position: relative;
            @include bgImg("musicoff");
            // .buttext {
            //   position: absolute;
            //   width: 0.28rem;
            //   height: 0.28rem;
            //   background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
            //   box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
            //     inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
            //     inset 1px 1px 1px 0px #ffffff;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   font-weight: 550;
            //   font-size: 0.12rem;
            //   color: #151413;
            //   top: -0.03rem;
            //   left: 0.05rem;
            // }
          }
        }
      }
    }
    .btnbox {
      width: 100%;
      margin-top: 0.12rem;
      .btn {
        width: 1.4rem;
        height: 0.42rem;
        background: url("../../assets/newImages/user-sbtn.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.18rem;
        color: #312f2d;
        margin: 0 auto;
        font-family: PingFang SC, PingFang SC;
      }
      .bbtn {
        width: 2.8rem;
        height: 0.42rem;
        background: url("../../assets/newImages/long-btn-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.18rem;
        color: #312f2d;
        margin: 0 auto;
        font-family: PingFang SC, PingFang SC;
      }
    }
    .userheadimg {
      position: absolute;
      width: 0.84rem;
      height: 0.84rem;
      top: -0.6rem;
      right: 0.95rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .usertitle {
      width: 100%;
      height: 0.8rem;
      text-align: center;
      line-height: 1rem;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
    }
    .changebox {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.38rem;
      background: #161514;
      margin: 0 auto 0.3rem;
      .typeitem {
        width: 0.96rem;
        height: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
      }
      .actypeitem {
        background: url("../../assets/newImages/send-code-bg.png") no-repeat;
        background-size: 100% 100%;
        color: #151413;
      }
    }
  }
}
</style>
